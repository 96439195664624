import { useState } from 'react';

import { useForm } from "react-hook-form";
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

import AuthService from '../services/authService';
import useSnackbarHook from '../hooks/useSnackbarHook';
import { CustomTextfield } from '../customMuiTextfield';
import usePasswordVisibilityToggle from '../hooks/usePasswordVisibilityToggle';
import useFormValidationRules from '../hooks/useFormValidationRules';

export default function PasswordRecovery(props = {}) {
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register: registerEmail, formState: { errors: emailFormErrors }, handleSubmit: handleSubmitEmail, watch } = useForm();
    const { register: registerPasswordForm, formState: { errors: passwordFormErrors }, handleSubmit: handleSubmitPasswordForm, getValues } = useForm({
        defaultValues: {
            recoveryCode: '',
            newPassword: '',
            confirmPassword: '',
        }
    });
    const { validationRules } = useFormValidationRules();
    const { t } = useTranslation();
    const { Alert, alertState, setAlertState } = useSnackbarHook();
    const userEmail = watch('email');

    const sendRecoveryCode = async (userInput) => {
        const response = await AuthService.sendPasswordRecoveryCode(userInput);
        if (response === AuthService.responses.success) {
            setIsCodeSent(true);
            setAlertState({ message: t('forms.instructions.complete_password_reset'), showAlert: true, severity: 'info' });
        } else if (response === AuthService.responses.userNotConfirmed) {
            setAlertState({ message: t('forms.errors.not_verified'), showAlert: true, severity: 'warning' });
        } else if (response === AuthService.responses.userNotFound) {
            setAlertState({ ...alertState, message: t('forms.errors.user_not_found'), showAlert: true, severity: 'error' })
        } else {
            setAlertState({ ...alertState, message: t('forms.errors.failed'), showAlert: true, severity: 'error' })
        }
    }

    const setNewPassword = async (userInput) => {
        const completeUserInfo = { email: userEmail, ...userInput };
        const response = await AuthService.handlePasswordRecovery(completeUserInfo);
        if (response === AuthService.responses.success) {
            setAlertState({ message: t('forms.successful_password_reset'), showAlert: true, severity: 'success' });
            setTimeout(() => { props.routerTo('/login'); }, 6100);
        } else if (response === AuthService.responses.codeMismatch) {
            setAlertState({ message: t('forms.errors.code_mismatch'), showAlert: true, severity: 'error' });
        } else if (response === AuthService.responses.attemptLimitExceeded) {
            setAlertState({ ...alertState, message: t('forms.errors.limit_attempts'), showAlert: true, severity: 'error' });
        } else if (response === AuthService.responses.expiredCode) {
            setAlertState({ ...alertState, message: t('forms.errors.expired_code'), showAlert: true, severity: 'error' });
        } else {
            setAlertState({ ...alertState, message: t('forms.errors.failed'), showAlert: true, severity: 'error' })
        }
    }

    const emailForm = (
        <form className='boxed-form small' onSubmit={handleSubmitEmail(sendRecoveryCode)}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                    <CustomTextfield type='email' label={t('forms.labels.email')} InputLabelProps={{ shrink: true, className: 'custom-label' }} required
                        {...registerEmail('email', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.email } })}
                        error={emailFormErrors.email ? true : false} helperText={emailFormErrors?.email?.message} placeholder={t('forms.placeholders.email')}
                    />
                    <button type='submit' className='submit-button'>{t('forms.get_code')}</button>
                </Grid>
                <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
            </Grid>
        </form>
    )

    const newPasswordForm = (
        <>
            <form className='boxed-form small' onSubmit={handleSubmitPasswordForm(setNewPassword)}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                    <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                    <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                        <CustomTextfield type='text' label={t('forms.labels.verification_code')} InputLabelProps={{ shrink: true, className: 'custom-label' }} required
                            {...registerPasswordForm('recoveryCode', { required: { ...validationRules.requiredField } })}
                            error={passwordFormErrors.recoveryCode ? true : false} helperText={passwordFormErrors?.recoveryCode?.message}
                        />
                        <CustomTextfield label={t('forms.labels.new_password')} type={showPassword ? 'text' : 'password'} helperText={passwordFormErrors?.newPassword?.message}
                            error={passwordFormErrors.newPassword ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowPassword, showPassword) }} InputLabelProps={{ className: 'custom-label', shrink: true }}
                            {...registerPasswordForm('newPassword', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password } })} required
                        />
                        <CustomTextfield label={t('forms.labels.confirm_password')} type={showConfirmPassword ? 'text' : 'password'} helperText={passwordFormErrors?.confirmPassword?.message}
                            error={passwordFormErrors.confirmPassword ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowConfirmPassword, showConfirmPassword) }}
                            {...registerPasswordForm('confirmPassword', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password }, validate: value => { return value === getValues('newPassword') || t('forms.errors.not_match_passwords') } })}
                            InputLabelProps={{ shrink: true, className: 'custom-label' }} required
                        />
                        <button type='submit' className='submit-button'>{t('actions.continue')}</button>
                    </Grid>
                    <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                </Grid>
            </form>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                    <button type='button' className='submit-button lower-button' onClick={() => sendRecoveryCode({ email: userEmail })}>{t('forms.get_another_code')}</button>
                </Grid>
                <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
            </Grid>
        </>
    )

    return (
        <>
            <div className='form-box sign-up'>
                <div className='form-titles'>
                    <p className='title'>{t('registration.password_recovery')}</p>
                </div>
                <div className='form-content'>
                    {(isCodeSent) ? newPasswordForm : emailForm}
                </div>
            </div>
            < Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
                <Alert severity={alertState.severity} sx={{ width: '100%', fontSize: '2rem' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </>
    )
}
