import { useState, useEffect } from 'react';

import { useForm } from "react-hook-form";
import { Checkbox, Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import useSnackbarHook from '../hooks/useSnackbarHook';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../context/state';
import AuthService from '../services/authService';
import StartupForm from '../startupDetail/startupForm';
import Api from '../../services/api';
import { CustomTextfield } from '../customMuiTextfield';
import usePasswordVisibilityToggle from '../hooks/usePasswordVisibilityToggle';
import useFormValidationRules from '../hooks/useFormValidationRules';

export default function SignUpForm(props = {}) {
    const [userPersonalInfo, setUserPersonalInfo] = useState({});
    const [renderStartupForm, setRenderStartupForm] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [signUpDone, setSignUpDone] = useState(false);
    const [startupRegistrationDone, setStartupRegistratioDone] = useState(false);
    const { Alert, alertState, setAlertState, handleError } = useSnackbarHook();
    const { register, formState: { errors }, handleSubmit, getValues } = useForm();
    const { validationRules } = useFormValidationRules();
    const context = useAppContext();
    const { t } = useTranslation();

    useEffect(async () => {
        const userId = localStorage.getItem('userId');
        if (userId !== null) {
            const [userStartup, apiError] = await Api.getUserStartup(userId);
            if (apiError) {
                handleError(apiError);
                return false;
            }
            if (typeof (userStartup)!== 'object') {
                setRenderStartupForm(true);
                setAlertState({ message: t('forms.instructions.complete_registration'), showAlert: true, severity: 'info' });
            }
        }
    }, []);

    const routerTo = (url) => {
        props.routerTo(url);
    }

    const signUp = async (userInput) => {
        if (!signUpDone) {
            const signUpResponse = await AuthService.handleSignUp({ ...userInput });
            if (signUpResponse === AuthService.responses.success) {
                setSignUpDone(true);
                setAlertState({ message: t('forms.successful_signup'), showAlert: true, severity: 'success' });
                setTimeout(() => { props.routerTo('/'); }, 6100);
            } else if (signUpResponse === AuthService.responses.userExists) {
                setAlertState({ message: t('forms.errors.user_exists'), showAlert: true, severity: 'error' });
            } else {
                setAlertState({ message: t('forms.errors.failed'), showAlert: true, severity: 'error' });
            }
        }
    }

    useEffect(() => {
        setUserPersonalInfo({ ...context.sharedState.credentials });
    }, [context.sharedState.credentials])

    const handleStartupRegistration = async (startupInfo) => {
        if (!startupRegistrationDone) {
            const signInResponse = await AuthService.handleSignIn({ ...userPersonalInfo });
            if (signInResponse === AuthService.responses.success) {
                const idToken = localStorage.getItem('idToken');
                const userId = localStorage.getItem('userId');
                const date = new Date()
                const currentDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                const normalizedStartupName = startupInfo.name.trim().toLowerCase();
                const completeStartupInfo = {
                    ...startupInfo,
                    description: startupInfo.description.replaceAll('"', "'"),
                    name: normalizedStartupName,
                    contactId: userId,
                    contactEmail: userPersonalInfo.email,
                    created: currentDate,
                }
                const [response, apiError] = await Api.postStartup(completeStartupInfo, idToken);
                if (apiError) {
                    handleError(apiError);
                    return false;
                }
                setStartupRegistratioDone(true);
                setAlertState({ message: t('registration.successful_startup_registration'), showAlert: true, severity: 'success' });
                setTimeout(() => { props.routerTo('/'); }, 6100);
            } else if (signInResponse === AuthService.responses.userNotConfirmed) {
                setAlertState({ message: t('forms.errors.not_verified'), showAlert: true, severity: 'warning' });
            } else {
                setAlertState({ message: t('forms.errors.failed'), showAlert: true, severity: 'error' });
            }
        }
    }

    const userForm = (
        <form className='boxed-form small' onSubmit={handleSubmit(signUp)}>
            <CustomTextfield type='email' label={t('forms.labels.email')} InputLabelProps={{ shrink: true, className: 'custom-label' }} required
                {...register('email', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.email } })}
                error={errors.email ? true : false} helperText={errors?.email?.message} placeholder={t('forms.placeholders.email')}
            />
            <CustomTextfield label={t('forms.labels.password')} type={showPassword ? 'text' : 'password'} helperText={errors?.password?.message}
                error={errors.password ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowPassword, showPassword) }} InputLabelProps={{ className: 'custom-label', shrink: true }}
                {...register('password', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password } })} required />
            <CustomTextfield label={t('forms.labels.confirm_password')} type={showConfirmPassword ? 'text' : 'password'} helperText={errors?.confirmPassword?.message}
                error={errors.confirmPassword ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowConfirmPassword, showConfirmPassword) }}
                {...register('confirmPassword', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password }, validate: value => { return value === getValues('password') || t('forms.errors.not_match_passwords') } })}
                InputLabelProps={{ shrink: true, className: 'custom-label' }} required
            />

            <span className='checkbox-wrapper'>
                <Checkbox id='terms-conditions' className='terms-agreement'
                    sx={{
                        color: '#4EBAB8',
                        '&.Mui-checked': {
                            color: '#4EBAB8',
                        }
                    }} required />
                <label htmlFor='terms-conditions' className='terms-agreement-label'><span>{t("forms.labels.agree_terms_and_conditions")}</span><a className='legal-link' href='/politicas/condiciones-de-uso' target='_blank'>{t("legal_agreements.terms_and_conditions")}</a> </label>
            </span>
            <button type='submit' className='submit-button'>{t('registration.sign_up')}</button>
            <span className='form-links-wrapper'>
                <span className='link-label'>{t('registration.login_link_label')}</span><span className='legal-link' onClick={() => routerTo('/login')}>{t('registration.login_link')}</span>
            </span>
        </form>
    )

    return (
        <>
            <div className='form-box sign-up'>
                <div className='form-titles'>
                    <p className='title' onClick={() => { setRenderStartupForm(false) }}>{t('registration.signup_link')}</p>
                </div>
                <div className='form-content'>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                        <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                            {(renderStartupForm) ? <StartupForm handleStartupRegistration={handleStartupRegistration} /> : userForm}
                        </Grid>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                    </Grid>
                </div>
            </div>
            <Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
                <Alert severity={alertState.severity} sx={{ width: '100%', fontSize: '2rem' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </>
    )
}