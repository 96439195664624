import { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import Subscribe from "../subscribe/subscribe";
import PasswordRecovery from "./passwordRecovery";
import SignInForm from "./signInForm";
import SignUpForm from "./signUpForm";

export default function Registration(props = {}) {
    const [location, setLocation] = useState('');
    const { t } = useTranslation();
    const pathNames = {
        signUpForm: '/registro',
        signInForm: '/login'
    };

    useEffect(() => {
        setLocation(props.location);
    }, [props.location])

    const routerTo = (url) => {
        props.routerTo(url);
    }

    let formRender;

    if (location === pathNames.signInForm) {
        formRender = <SignInForm routerTo={routerTo} />
    } else if (location === pathNames.signUpForm) {
        formRender = <SignUpForm routerTo={routerTo} />
    } else {
        formRender = <PasswordRecovery routerTo={routerTo} />
    }

    return (
        <section className='registration'>
            <div className='section-header'>
                <p className='section-title'>{t('registration.registration_time')}</p>
            </div>
            <div className="main-focus">
                <div className="registration-text">
                    <p>{t('registration.explanation')}</p>
                </div>
                {formRender}
                <span className="circle" />
            </div>
            <div className="framed-text">
                <span className="triangle shape-right" />
                <span className="triangle shape-left" />
                <h4>{t('registration.quote')}</h4>
            </div>
            <Subscribe />
        </section>
    )
}