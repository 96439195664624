import { useState } from 'react';

import { useForm } from "react-hook-form";
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../context/state';
import AuthService from '../services/authService';
import useSnackbarHook from '../hooks/useSnackbarHook';
import Api from '../../services/api';
import { CustomTextfield } from '../customMuiTextfield';
import usePasswordVisibilityToggle from '../hooks/usePasswordVisibilityToggle';
import useFormValidationRules from '../hooks/useFormValidationRules';

export default function SignInForm(props = {}) {
    const [isFirtsSignIn, setIsFirstSignIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const context = useAppContext();
    const { t } = useTranslation();
    const { register: signInFormRegister, handleSubmit: handleSubmitSignIn, formState: { errors: signInFormErrors } } = useForm({
        defaultValues: {
            email: '',
            password: '',
        }
    });
    const { register: passwordFormRegister, handleSubmit: handleSubmitSetPassword, watch, formState: { errors: passwordFormErrors } } = useForm({
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        }
    });
    const { validationRules } = useFormValidationRules();
    const { Alert, alertState, setAlertState, handleError } = useSnackbarHook();
    const watchNewPassword = watch('newPassword');

    const routerTo = (url) => {
        props.routerTo(url);
    }

    async function signIn(userInput) {
        const signInResponse = await AuthService.handleSignIn(userInput);
        context.setSharedState({ ...context.sharedState, credentials: { ...userInput } })
        if (signInResponse === AuthService.responses.success) {
            const userId = localStorage.getItem('userId');
            const [userStartup, apiError] = await Api.getUserStartup(userId);
            if (apiError) {
                handleError(apiError);
                return false;
            }
            if (typeof (userStartup) === 'object') {
                routerTo('/');
            } else {
                routerTo('/registro');
            }
        } else if (signInResponse === AuthService.responses.notAuthorized) {
            setAlertState({ ...alertState, message: t('forms.errors.credentials'), showAlert: true, severity: 'error' });
        } else if (signInResponse === AuthService.responses.newPasswordRequired) {
            setIsFirstSignIn(true);
        } else if (signInResponse === AuthService.responses.userNotConfirmed) {
            setAlertState({ message: t('forms.errors.not_verified'), showAlert: true, severity: 'warning' });
        } else if (signInResponse === AuthService.responses.userNotFound) {
            setAlertState({ ...alertState, message: t('forms.errors.user_not_found'), showAlert: true, severity: 'error' });
        } else {
            setAlertState({ ...alertState, message: t('forms.errors.failed'), showAlert: true, severity: 'error' });
        }
    }

    async function setPassword(userInput) {
        const setPasswordResponse = await AuthService.handleSetPassword(userInput);
        if (setPasswordResponse === AuthService.responses.success) {
            setAlertState({ ...alertState, message: t('forms.successful_password_reset'), showAlert: true, severity: 'success' });
            setTimeout(() => { props.routerTo('/perfil/empresa'); }, 6100);
        } else if (setPasswordResponse === AuthService.responses.notAuthorized) {
            setIsFirstSignIn(false);
            setAlertState({ ...alertState, message: t('forms.errors.session'), showAlert: true, severity: 'error' });
        } else {
            setIsFirstSignIn(false);
            setAlertState({ ...alertState, message: t('forms.errors.failed'), showAlert: true, severity: 'error' });
        }
    }

    const signInRender = (
        <div className='form-box sign-in'>
            <div className='form-titles'>
                <p className='title'>{t('registration.login_link')}</p>
            </div>
            <div className='form-content'>
                <form onSubmit={handleSubmitSignIn(signIn)} className='boxed-form small'>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                        <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                            <CustomTextfield id='email' label={t('forms.labels.email')} type='email' helperText={signInFormErrors?.email?.message} error={signInFormErrors.email ? true : false}
                                {...signInFormRegister('email', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.email } })}
                                InputLabelProps={{ shrink: true, className: 'custom-label' }} placeholder={t('forms.placeholders.email')}
                            />
                            <CustomTextfield id='password' label={t('forms.labels.password')} type={showPassword ? 'text' : 'password'} helperText={signInFormErrors?.password?.message}
                                error={signInFormErrors.password} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowPassword, showPassword) }}
                                InputLabelProps={{ shrink: true, className: 'custom-label' }}
                                {...signInFormRegister('password', { required: { ...validationRules.requiredField } })}
                            />
                        </Grid>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                    </Grid>
                    <button type='submit' className='submit-button'>{t('registration.sign_in')}</button>
                    <span className='form-links-wrapper'>
                        <span className='link-label'>{t('registration.signup_link_label')}</span><span className='legal-link' onClick={() => routerTo('/registro')}>{t('registration.signup_link')}</span>
                    </span>
                    <span className='form-links-wrapper'>
                        <span className='legal-link' onClick={() => routerTo('/recuperar-cuenta')}>{t('registration.forgot_password_link')}</span>
                    </span>
                </form>
            </div>
        </div>
    )

    const newPasswordRender = (
        <div className='form-box sign-in'>
            <div className='form-titles'>
                <p className='title'>{t('registration.login_link')}</p>
            </div>
            <div className='form-content'>
                <form onSubmit={handleSubmitSetPassword(setPassword)} className='boxed-form'>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                        <Grid xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                            <CustomTextfield id='newPassword' label={t('forms.labels.new_password')} type={showNewPassword ? 'text' : 'password'} helperText={passwordFormErrors?.newPassword?.message}
                                error={passwordFormErrors.newPassword ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowNewPassword, showNewPassword) }}
                                {...passwordFormRegister('newPassword', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password } })}
                                InputLabelProps={{ shrink: true, className: 'custom-label' }}
                            />
                            <CustomTextfield id='confirmPassword' label={t('forms.labels.confirm_password')} type={showConfirmPassword ? 'text' : 'password'} helperText={passwordFormErrors?.confirmPassword?.message}
                                error={passwordFormErrors.confirmPassword ? true : false} InputProps={{ endAdornment: usePasswordVisibilityToggle(setShowConfirmPassword, showConfirmPassword) }}
                                {...passwordFormRegister('confirmPassword', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.password }, validate: value => { return value === watchNewPassword || t('forms.errors.not_match_passwords') } })}
                                InputLabelProps={{ shrink: true, className: 'custom-label' }}
                            />
                        </Grid>
                        <Grid xs={0.75} sm={0.75} md={0.75} lg={0.75} xl={0.75} />
                    </Grid>
                    <button type='submit' className='submit-button'>{t('registration.sign_in')}</button>
                </form>
            </div>
        </div>
    )

    return (
        <>
            {(isFirtsSignIn) ? newPasswordRender : signInRender}
            < Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
                <Alert severity={alertState.severity} sx={{ width: '100%', fontSize: '2rem' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </>
    )
}